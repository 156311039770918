import React from 'react';
import { MDXProvider as Provider } from '@mdx-js/react';
import defaultComponents from './defaultComponents';
import { BreadcrubmsContextProvider } from '../../../util/context/BreadcrubmsContext';

const MDXProvider = ({ components = defaultComponents, element }) => (
  <BreadcrubmsContextProvider>
    <Provider components={components}>{element}</Provider>
  </BreadcrubmsContextProvider>
);

export default MDXProvider;

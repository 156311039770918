/* eslint-disable react/display-name */
/**
 * Copyright IBM Corp. 2016, 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { Link, Tag, TooltipIcon } from "@carbon/react";

import { GatsbyImage } from 'gatsby-plugin-image';
import { ResourceCard } from 'gatsby-theme-carbon';
import React from 'react';
import placeholder from './images/placeholder.svg';

const BrandsIndexListItem = React.memo(
    ({
        id,
        description,
        article,
        categories,
        image,
        slug
    }) => {
        let img;
        // console.log(image)
        if (image && image.layout) {
            img = (
                <GatsbyImage
                    // className="index-image" 
                    className="tile-image"
                    alt={`Картинка для ${article}`}
                    image={image}
                />
            );
        } else {
            img = (
                <img src={image ? image : placeholder} className="tile-image" alt={`Заглушка для ${article}`} />
            );
        }

        const linkProps = {
            className: 'component-index-item__link',
            rel: 'noopener noreferrer',
        };

        const websiteLinkProps = {
            ...linkProps,
            href: `/brand/${slug}`,
            onClick: () => { },
        };

        // const codeLinkProps = {
        //     ...linkProps,
        //     href: extLink,
        //     onClick: () => {},
        // };

        return (
            <ResourceCard
                title={`${article}`}
                subTitle=""
                // aspectRatio="2:1"
                actionIcon="arrowRight"
                href={`/brands/${slug}`}
            >
                <ul className='cds--resource-card__icon--tags'>
                    {categories?.map((cat, idx) => {
                        return (
                            <li key={idx}>
                                {cat}
                            </li>
                        )
                    })}
                </ul>
            </ResourceCard>
        );
    }
);

export default BrandsIndexListItem;
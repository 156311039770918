/* eslint-disable react/display-name */
/**
 * Copyright IBM Corp. 2016, 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { Link, Tag, TooltipIcon } from "@carbon/react";

import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import placeholder from './images/placeholder.svg';

const ProductsIndexListItem = React.memo(
    ({
        id,
        slug,
        description,
        brand_id,
        article,
        name,
        image,
        categories
    }) => {
        let img;
        // console.log(image)
        if (image && image?.layout) {
            img = (
                <GatsbyImage
                    // className="index-image" 
                    className="tile-image"
                    alt={`Картинка для ${article}`}
                    image={image}
                />
            );
        } else {
            img = (
                <img src={image ? image : placeholder} className="tile-image" alt={`Заглушка для ${article}`} />
            );
        }

        const linkProps = {
            className: 'component-index-item__link',
            rel: 'noopener noreferrer',
        };

        const websiteLinkProps = {
            ...linkProps,
            href: `/product/${slug}`,
            onClick: () => { },
        };

        // const codeLinkProps = {
        //     ...linkProps,
        //     href: extLink,
        //     onClick: () => {},
        // };

        return (
            <Link className="pal--catalog-tile" href={`/product/${slug}`}>
            {/* <div className="pal--catalog-tile" > */}
                <div className="pal--catalog-tile__content-wrapper">
                    <div className="pal--catalog-tile__header-container">
                        <div className="pal--catalog-tile__icon-container">
                            {img} 
                            {/* <img src={image} className="tile-image"  alt="" /> */}
                        </div>
                        <div className="pal--catalog-tile__header">
                            <p className="pal--catalog-tile__header-name">{article}</p>
                            <ul className="pal--catalog-tile__tag-container">
                                <li className="pal--catalog-tile__tag"></li></ul>
                        </div>
                    </div>
                    <div className="pal--catalog-tile__desc-container">
                        <div className="pal--catalog-tile__desc">
                            <div className="pal--catalog-tile__desc-ellipsis">
                                <p className="pal--catalog-tile__desc">{description?.replace(/(<([^>]+)>)/ig, "")}</p>
                            </div>
                        </div>
                        <ul className="pal--catalog-tile__tag-container">
                            {!categories && (<li key={'name'}>{name}</li>)}

                            {categories?.map((cat, idx) => {
                                return (
                                    <li key={idx}>
                                        {cat.title}
                                    </li>
                                )
                            })}

                        </ul>
                    </div>
                </div>
            </Link>
        );
    }
);

export default ProductsIndexListItem;
import defaultComponents from 'gatsby-theme-carbon/src/components/MDXProvider/defaultComponents';

import HomepageVideo from '../../../components/HomepageVideo';
import BrandEnteties from '../../../components/BrandsEnteties';
import BrandsIndexPage from '../../../components/BrandsIndex';
import ProductsIndexPage from '../../../components/ProductsIndex';
import Carousel from '../../../components/Carousel/Carousel';
import ArticlesIndex from '../../../components/ArticlesIndex/ArticlesIndex';

const components = {
  ...defaultComponents,
  HomepageVideo,
  BrandEnteties,
  BrandsIndexPage,
  ProductsIndexPage,
  Carousel,
  ArticlesIndex
};
export default components;
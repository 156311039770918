import { useStaticQuery, graphql } from 'gatsby';

export function useCategoryItems() {
  const {
    allIndustratorCategory: { edges },
  } = useStaticQuery(graphql`
    query LEFT_NAV_QUERY1 {
      allIndustratorCategory {
        edges {
          node {
            id
            slug
            title
            title_path
            title_path_full
            parent {
              id
              ... on IndustratorCategory {
                id
                slug
                title
              }
            }
            tree
            parent_tree{
              id
              slug
              title
            }
          }
        }
      }
    }
  `);

  const navItems = edges.map(({ node }) => {
    return node
  });
  return navItems;
}


export function useNavItems() {
  const categories = useCategoryItems();

  const navItems = categories.map(category => {
    const { id, slug, title, tree, parent } = category;
    const cat = {
      id,
      slug,
      title,
      tree: `/category/${tree}`,
      parent
    }
    return cat
  });
  return navItems;
}

import React from 'react';

import DDSCarousel from '@carbon/ibmdotcom-web-components/es/components-react/carousel/carousel.js';
import CarouselCard from './CarouselCard';

function Carousel(props) {
  const { leftHeader, leftText, items } = props;

  return (

    <DDSCarousel  pageSize={4}>
      {items.map((item, idx) => (
        <CarouselCard
          logo={item.logo}
          color={item.color}
          key={`carousel-${idx}`}
          href={item.href}
          image={item.image}
          heading={item.heading}
          copy={item.copy}
        />
      ))}
    </DDSCarousel>
  );
}

export default Carousel;
// https://www.w3.org/TR/wai-aria-practices/examples/combobox/aria1.1pattern/listbox-combo.html/#ex1
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/role-has-required-aria-props */

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
  createContext,
} from 'react';
import { Close20, Search20 } from '@carbon/icons-react';
import _throttle from 'lodash.throttle';
import { navigate } from 'gatsby';
import cx from 'classnames';
// import NavContext from 'gatsby-theme-carbon/src/util/context/NavContext';
import { useOnClickOutside } from 'gatsby-theme-carbon/src/util/hooks';
import axios from 'axios';
import { useDebounce } from '../../../util/hooks/useDebounce';
import {
  container,
  input,
  label,
  searchButton,
  searchButtonClose,
  inputWrapper,
  inputFocusWithin,
  hidden,
  inactive,
} from './Search.module.scss';

import Menu, { MenuContext } from './Menu';

export const SearchContext = createContext();
const MAX_RESULT_LIST_SIZE = 8;
const API_URL = "https://api.industrator.ru/v1";

function objectToQuerystring(obj) {
  return Object.keys(obj).reduce(function (str, key, i) {
    var delimiter, val;
    delimiter = (i === 0) ? '?' : '&';
    key = encodeURIComponent(key);
    val = encodeURIComponent(JSON.stringify(obj[key]));
    return [str, delimiter, key, '=', val].join('');
  }, '');
}

const getProductData = async (
  api,
  filters
) => {
  let uri = objectToQuerystring(filters);
  const response = await axios.get(
    `${API_URL}${api}${uri}`
  )
  let totalCount = 0;
  if ('content-range' in response.headers) {
    totalCount = Number.parseInt(response.headers['content-range'].split('/')[1]);
  }
  const products = response.data;
  return { totalCount, products }
}

const search = _throttle((queryString) => {
  if (window.__LUNR1__) {
    try {
      const lunrIndex = window.__LUNR1__.multi;
      // console.log(lunrIndex)
      const searchResults = lunrIndex.index
        .search(`${queryString}*`)
        .slice(0, MAX_RESULT_LIST_SIZE);
      return searchResults.map(({ ref }) => lunrIndex.store[ref]);
    } catch {
      console.error(`Lunr is having issues querying for '${queryString}'`);
    }
  }
}, 150);

// TODO pass magnifying ref for escape/close? keep focus within outline for input,
const SearchInput = () => {
  const optionsRef = useRef([]);
  const [focusedItem, setFocusedItem] = useState(0);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const openButtonRef = useRef(null);
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [query, setQuery] = useState('');
  const [debouncedSearchValue] = useDebounce(query, 300);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchIsOpen = true;

  const getData = (search = debouncedSearchValue) => {
    setLoading(true);
    setResults([]);
    const startOffset = 0;
    const endOffset = (startOffset + MAX_RESULT_LIST_SIZE) - 1;
    const filters = {
      article: search,
    };
    getProductData('/products', {
      "range": [startOffset, endOffset],
      "filter": filters
    }).then((result) => {
      setResults(result.products);
    }).finally(() => {
      setLoading(false);
    })
  }

  const clearAndClose = useCallback(() => {
    setQuery('');
    // toggleNavState('searchIsOpen', 'close');
    if (openButtonRef.current) {
      openButtonRef.current.focus();
    }
  }, []);

  const value = useMemo(
    () => ({ optionsRef, focusedItem, setFocusedItem, clearAndClose }),
    [clearAndClose, focusedItem]
  );

  useEffect(() => {
    if (inputRef.current && searchIsOpen) {
      inputRef.current.focus();
      setInputIsFocused(true);
    }
  }, [searchIsOpen]);

  useOnClickOutside(containerRef, () => {
    // toggleNavState('searchIsOpen', 'close');
    setQuery('');
  });

  useEffect(() => {
    if (debouncedSearchValue !== '') {
      getData(debouncedSearchValue);
    }
    return () => {
      setResults([]);
    };
  }, [debouncedSearchValue]);

  const onKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        setFocusedItem((focusedItem + 1) % results.length);
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        if (focusedItem - 1 < 0) {
          setFocusedItem(results.length - 1);
        } else {
          setFocusedItem(focusedItem - 1);
        }
        break;
      }
      case 'Escape': {
        e.preventDefault();
        if (query === '') {
          clearAndClose();
        } else {
          setQuery('');
          inputRef.current.focus();
        }
        break;
      }
      case 'Enter': {
        e.preventDefault();
        if (results[focusedItem]) {
          navigate(results[focusedItem].path);
        }
        break;
      }
      default:
    }
  };

  // Check if there are results, if there are the listbox is open
  // and set focus to the first menu item
  const getAriaActiveDescendantValue = () => {
    if (results.length > 0) {
      return `menu-item-${focusedItem}`;
    }

    return null;
  };

  return (
    <MenuContext.Provider value={value}>
      <div
        ref={containerRef}
        className={cx(container, {
          [hidden]: !searchIsOpen,
          [inputFocusWithin]: inputIsFocused,
        })}
        role="search">
        <label htmlFor="search-input" id="search-label" className={label}>
          Поиск
        </label>
        <div
          className={inputWrapper}
          aria-owns="search-menu"
          aria-haspopup="menu">
          <button
            tabIndex={searchIsOpen ? '-1' : '0'}
            className={cx(searchButton, {
              [inactive]: searchIsOpen,
            })}
            ref={openButtonRef}
            type="button"
            aria-label="Открыть поиск"
            aria-expanded={searchIsOpen}
            onClick={() => {
              // toggleNavState('searchIsOpen', 'open');
              // toggleNavState('switcherIsOpen', 'close');
            }}>
            <Search20 description="Открыть поиск" />
          </button>
          <input
            autoComplete="off"
            tabIndex={searchIsOpen ? '0' : '-1'}
            onBlur={() => setInputIsFocused(false)}
            onFocus={() => setInputIsFocused(true)}
            ref={inputRef}
            type="text"
            aria-autocomplete="list"
            aria-controls="search-menu"
            aria-activedescendant={getAriaActiveDescendantValue()}
            className={cx(input, {
              [hidden]: !searchIsOpen,
            })}
            aria-label="Site wide search input"
            id="search-input"
            placeholder="Бренд, артикул, или наименование"
            value={query}
            onKeyDown={onKeyDown}
            onChange={(evt) => setQuery(evt.target.value)}
          />
          {/* <button
            tabIndex={searchIsOpen ? '0' : '-1'}
            className={cx(searchButton, searchButtonClose, {
              [hidden]: !searchIsOpen,
            })}
            type="button"
            aria-label="Очистить поиск"
            onClick={clearAndClose}>
            <Close20 description="Очистить поиск" />
          </button> */}
        </div>
        <Menu onKeyDown={onKeyDown} results={results} />
      </div>
    </MenuContext.Provider>
  );
};

export default SearchInput;

/**
 * Copyright IBM Corp. 2016, 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from "gatsby-plugin-image"
import { useCategoryItems, useNavItems } from '../../gatsby-theme-carbon/util/NavItems';

const BRANDS_DATA = graphql`
query {
  allIndustratorBrand {
    edges{
      node {
        article
        id
        slug
        description
        categories_ids
      }
    }
  }
}
     
 `;



export function useBrandsData(filters = {}) {
  const { allIndustratorBrand } = useStaticQuery(BRANDS_DATA);

  const categoriesA = useCategoryItems();
  // console.log(filters);
  let edges = allIndustratorBrand.edges.filter(({ node }) => {
    // If there are no filters, return true.
    if (Object.keys(filters).length === 0) {
      return true;
    }
    const { article, categories_ids } = node;

    // console.log(categories_ids);
    let isNiceCat = false;
    if (filters.categories?.length) {
      filters.categories.map(cat => {
        const category_id = cat.split('-')[1];
        if (!isNiceCat)
          isNiceCat = categories_ids?.includes(category_id);

      })

    } else {
      isNiceCat = true;
    }

    return isNiceCat;
  });

  const images = [];
  const allCategories = categoriesA.filter(c => c.parent === null).map(c => c.title);
  const brands = edges.map((edge) => {
    const { node } = edge;
    const { categories_ids } = node;
    const categories = [];
    categories_ids?.forEach(element => {
      const cat = categoriesA.filter(c => c.id === `category-${element}`);
      if (cat.length) {
        categories.push(cat[0].title);
      }
      // while (element.charAt(0) === ' ') {
      //   element = element.substring(1);
      // }
      // if (!allCategories.includes(element)) {
      //   allCategories.push(element);
      // }
    });
    // console.log(categories);
    return {
      ...node,
      categories,
      id: node.id,
      image: images.length ? images[0] : ''
    };
  });

  return { brands, allCategories };
}

// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Callout-module--cds--assistive-text--982d1";
export var cdsLayoutConstraintDensity__defaultCondensed = "Callout-module--cds--layout-constraint--density__default-condensed--25026";
export var cdsLayoutConstraintDensity__defaultNormal = "Callout-module--cds--layout-constraint--density__default-normal--29d4e";
export var cdsLayoutConstraintDensity__maxCondensed = "Callout-module--cds--layout-constraint--density__max-condensed--b22fa";
export var cdsLayoutConstraintDensity__maxNormal = "Callout-module--cds--layout-constraint--density__max-normal--dfb36";
export var cdsLayoutConstraintDensity__minCondensed = "Callout-module--cds--layout-constraint--density__min-condensed--7b053";
export var cdsLayoutConstraintDensity__minNormal = "Callout-module--cds--layout-constraint--density__min-normal--b3ff0";
export var cdsLayoutConstraintSize__default2xl = "Callout-module--cds--layout-constraint--size__default-2xl--b479c";
export var cdsLayoutConstraintSize__defaultLg = "Callout-module--cds--layout-constraint--size__default-lg--0da92";
export var cdsLayoutConstraintSize__defaultMd = "Callout-module--cds--layout-constraint--size__default-md--6ab14";
export var cdsLayoutConstraintSize__defaultSm = "Callout-module--cds--layout-constraint--size__default-sm--a42e8";
export var cdsLayoutConstraintSize__defaultXl = "Callout-module--cds--layout-constraint--size__default-xl--8a606";
export var cdsLayoutConstraintSize__defaultXs = "Callout-module--cds--layout-constraint--size__default-xs--6f866";
export var cdsLayoutConstraintSize__max2xl = "Callout-module--cds--layout-constraint--size__max-2xl--f4e49";
export var cdsLayoutConstraintSize__maxLg = "Callout-module--cds--layout-constraint--size__max-lg--234f5";
export var cdsLayoutConstraintSize__maxMd = "Callout-module--cds--layout-constraint--size__max-md--0ba3c";
export var cdsLayoutConstraintSize__maxSm = "Callout-module--cds--layout-constraint--size__max-sm--7f25a";
export var cdsLayoutConstraintSize__maxXl = "Callout-module--cds--layout-constraint--size__max-xl--040ca";
export var cdsLayoutConstraintSize__maxXs = "Callout-module--cds--layout-constraint--size__max-xs--64605";
export var cdsLayoutConstraintSize__min2xl = "Callout-module--cds--layout-constraint--size__min-2xl--fb866";
export var cdsLayoutConstraintSize__minLg = "Callout-module--cds--layout-constraint--size__min-lg--a9dde";
export var cdsLayoutConstraintSize__minMd = "Callout-module--cds--layout-constraint--size__min-md--fc160";
export var cdsLayoutConstraintSize__minSm = "Callout-module--cds--layout-constraint--size__min-sm--365b4";
export var cdsLayoutConstraintSize__minXl = "Callout-module--cds--layout-constraint--size__min-xl--5c479";
export var cdsLayoutConstraintSize__minXs = "Callout-module--cds--layout-constraint--size__min-xs--6d4f2";
export var cdsLayoutDensityCondensed = "Callout-module--cds--layout--density-condensed--d4880";
export var cdsLayoutDensityNormal = "Callout-module--cds--layout--density-normal--ba5b9";
export var cdsLayoutSize2xl = "Callout-module--cds--layout--size-2xl--389db";
export var cdsLayoutSizeLg = "Callout-module--cds--layout--size-lg--31166";
export var cdsLayoutSizeMd = "Callout-module--cds--layout--size-md--d3e08";
export var cdsLayoutSizeSm = "Callout-module--cds--layout--size-sm--902e5";
export var cdsLayoutSizeXl = "Callout-module--cds--layout--size-xl--fbaa2";
export var cdsLayoutSizeXs = "Callout-module--cds--layout--size-xs--e3ad1";
export var cdsVisuallyHidden = "Callout-module--cds--visually-hidden--3bc30";
export var firstColumn = "Callout-module--firstColumn--39d39";
export var grid = "Callout-module--grid--623e3";
export var hideFeedback = "Callout-module--hide-feedback--0adef";
export var row = "Callout-module--row--897bf";
export var secondColumn = "Callout-module--secondColumn--da1a4";
export var showFeedback = "Callout-module--show-feedback--92257";
export var skeleton = "Callout-module--skeleton--77a4c";
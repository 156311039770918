import React from 'react';
import { Helmet } from 'react-helmet';
import { useMetadata } from 'gatsby-theme-carbon/src/util/hooks';

const Meta = ({ pageTitle, pageDescription, pageKeywords, pageData, titleType }) => {
  const { title, description, keywords, lang } = useMetadata();

  const getPageTitle = () => {
    switch (titleType) {
      case 'page':
        // use site title for fallback
        return pageTitle || title;
      case 'site':
        return title;
      case 'append':
        return `${title}${pageTitle ? ` – ${pageTitle}` : ''}`;
      case 'prepend':
        return `${pageTitle ? `${pageTitle} – ` : ''}${title}`;
      default:
        return null;
    }
  };

  const getJsonLd = (data) => {
    const website = {
      "@context": "http://www.schema.org",
      "@type": "WebSite",
      "name": "Industrator Marketplace",
      "alternateName": "Industrator",
      "url": "https://industrator.ru/"
    };
    const organization = {
      "@context": "http://www.schema.org",
      "@type": "Organization",
      "name": "Industrator",
      "url": "https://industrator.ru",
      "sameAs": [
        "https://www.industrator.ru"
      ],
      "description": "Industrator marketplace",
      "openingHours": "Mo, Tu, We, Th, Fr, Sa, Su -"
    };
    let product = "";
    if (data && data.type === 'product') {
      product =
      {
        "@context": "http://schema.org/",
        "@type": "Product",
        "name": `${data.name}`,
        "image": `${data.image}`,
        "description": `${data.description}`,
        "sku": `${data.article}`,
        "mpn": `${data.article}`,
        "brand": {
          "@type": "Brand",
          "name": `${data.brand}`
        },
        "offers": {
          "@type": "AggregateOffer",
          "priceCurrency": `${data.priceCurrency}`,
          "lowPrice": `${data.lowPrice}`,
          "highPrice": `${data.highPrice}`,
          "offerCount": `${data.offerCount}`,
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "5",
          "ratingCount": "5",
          "reviewCount": "1"
        },
        "review": [
          {
            "@type": "Review",
            "author": {
              "@type": "Person",
              "name": "DMG"
            },
            "reviewBody": "Very Nice!"
          }
        ]
      };
    }
    return [website, organization, product];
  }

  return (
    <Helmet
      title={getPageTitle()}
      meta={[
        {
          name: 'description',
          content: pageDescription || description,
        },
        {
          name: 'keywords',
          content: pageKeywords || keywords,
        },
      ]}>

      <script type='application/ld+json'>{JSON.stringify(getJsonLd(pageData))}</script>
      <meta charset="utf-8" />
      <meta name="robots" content="noindex, nofollow" />
      <html lang={lang} />
    </Helmet>
  );
};

export default Meta;

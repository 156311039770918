/**
 * Copyright IBM Corp. 2016, 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { Column, Dropdown, Row } from "@carbon/react";
import PropTypes from 'prop-types';
import React from 'react';

function BrandsIndexFilter({ initialSortOption, sortOptions, onSort, filters, onFilter, hideBrand }) {
  return (
    <div className="component-index-sort-container">
      <Row className="component-index-sort">
        <Dropdown
          id="component-index-sort"
          initialSelectedItem={initialSortOption}
          items={sortOptions}
          light
          onChange={({ selectedItem }) => {
            onSort(selectedItem);
          }}

          label="Сортировка:"
          titleText="Сортировка:"
        />
        {filters.filter(f => hideBrand ? f.title !== 'Категории' : true).map(({ title, options, key }) => (
          <Dropdown
            id={`component-index-sort-${key}`}
            initialSelectedItem={'Все'}
            items={options}
            light
            onChange={({ selectedItem }) => {
              onFilter(title, selectedItem);
            }}

            label={`${title}:`}
            titleText={`${title}:`}
          />
        ))}
      </Row>
    </div>
  );
}

BrandsIndexFilter.propTypes = {
  /**
   * Receive an update for the latest value and use it to update the passed in
   * `value`
   */
  onSort: PropTypes.func.isRequired,

  /**
   * Provide the available sort options to be selected
   */
  sortOptions: PropTypes.arrayOf(PropTypes.string),
};

export default BrandsIndexFilter;

// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "LeftNav-module--cds--assistive-text--43500";
export var cdsLayoutConstraintDensity__defaultCondensed = "LeftNav-module--cds--layout-constraint--density__default-condensed--9c7f8";
export var cdsLayoutConstraintDensity__defaultNormal = "LeftNav-module--cds--layout-constraint--density__default-normal--e3a4b";
export var cdsLayoutConstraintDensity__maxCondensed = "LeftNav-module--cds--layout-constraint--density__max-condensed--57139";
export var cdsLayoutConstraintDensity__maxNormal = "LeftNav-module--cds--layout-constraint--density__max-normal--b9064";
export var cdsLayoutConstraintDensity__minCondensed = "LeftNav-module--cds--layout-constraint--density__min-condensed--c6c57";
export var cdsLayoutConstraintDensity__minNormal = "LeftNav-module--cds--layout-constraint--density__min-normal--5585b";
export var cdsLayoutConstraintSize__default2xl = "LeftNav-module--cds--layout-constraint--size__default-2xl--b0174";
export var cdsLayoutConstraintSize__defaultLg = "LeftNav-module--cds--layout-constraint--size__default-lg--74504";
export var cdsLayoutConstraintSize__defaultMd = "LeftNav-module--cds--layout-constraint--size__default-md--1f266";
export var cdsLayoutConstraintSize__defaultSm = "LeftNav-module--cds--layout-constraint--size__default-sm--4146d";
export var cdsLayoutConstraintSize__defaultXl = "LeftNav-module--cds--layout-constraint--size__default-xl--7130b";
export var cdsLayoutConstraintSize__defaultXs = "LeftNav-module--cds--layout-constraint--size__default-xs--99e3a";
export var cdsLayoutConstraintSize__max2xl = "LeftNav-module--cds--layout-constraint--size__max-2xl--1e7cb";
export var cdsLayoutConstraintSize__maxLg = "LeftNav-module--cds--layout-constraint--size__max-lg--ba849";
export var cdsLayoutConstraintSize__maxMd = "LeftNav-module--cds--layout-constraint--size__max-md--0f458";
export var cdsLayoutConstraintSize__maxSm = "LeftNav-module--cds--layout-constraint--size__max-sm--9d352";
export var cdsLayoutConstraintSize__maxXl = "LeftNav-module--cds--layout-constraint--size__max-xl--40ff7";
export var cdsLayoutConstraintSize__maxXs = "LeftNav-module--cds--layout-constraint--size__max-xs--4f513";
export var cdsLayoutConstraintSize__min2xl = "LeftNav-module--cds--layout-constraint--size__min-2xl--613ff";
export var cdsLayoutConstraintSize__minLg = "LeftNav-module--cds--layout-constraint--size__min-lg--075be";
export var cdsLayoutConstraintSize__minMd = "LeftNav-module--cds--layout-constraint--size__min-md--ebf73";
export var cdsLayoutConstraintSize__minSm = "LeftNav-module--cds--layout-constraint--size__min-sm--c3972";
export var cdsLayoutConstraintSize__minXl = "LeftNav-module--cds--layout-constraint--size__min-xl--fc0f0";
export var cdsLayoutConstraintSize__minXs = "LeftNav-module--cds--layout-constraint--size__min-xs--7ca49";
export var cdsLayoutDensityCondensed = "LeftNav-module--cds--layout--density-condensed--3d8ab";
export var cdsLayoutDensityNormal = "LeftNav-module--cds--layout--density-normal--421a5";
export var cdsLayoutSize2xl = "LeftNav-module--cds--layout--size-2xl--12913";
export var cdsLayoutSizeLg = "LeftNav-module--cds--layout--size-lg--cf811";
export var cdsLayoutSizeMd = "LeftNav-module--cds--layout--size-md--651d4";
export var cdsLayoutSizeSm = "LeftNav-module--cds--layout--size-sm--462cc";
export var cdsLayoutSizeXl = "LeftNav-module--cds--layout--size-xl--789ef";
export var cdsLayoutSizeXs = "LeftNav-module--cds--layout--size-xs--ff512";
export var cdsVisuallyHidden = "LeftNav-module--cds--visually-hidden--edadf";
export var currentItem = "LeftNav-module--current-item--5119a";
export var divider = "LeftNav-module--divider--a4cb9";
export var dividerSpace = "LeftNav-module--divider-space--867ac";
export var hideFeedback = "LeftNav-module--hide-feedback--519ce";
export var outboundLink = "LeftNav-module--outboundLink--3b937";
export var showFeedback = "LeftNav-module--show-feedback--36df9";
export var sideNavDark = "LeftNav-module--side-nav--dark--7dd10";
export var sideNavWhite = "LeftNav-module--side-nav--white--0a748";
export var skeleton = "LeftNav-module--skeleton--c4362";
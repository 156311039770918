import React, { useReducer, useState } from 'react';

const BreadcrubmsContext = React.createContext({
  count: 0
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'open':
      return { ...state, [action.nav]: true };
    case 'close':
      return { ...state, [action.nav]: false };
    default:
      return { ...state, [action.nav]: !state[action.nav] };
  }
};
export const BreadcrubmsContextProvider = ({ children }) => {
  const [{ count }, dispatch] = useReducer(reducer, {
    count: 0
  });

  const [isBrands, setIsBrands] = useState(false);
  const [path, setPath] = useState({})
  const value = {
    count,
    isBrands,
    setIsBrands,
    path, setPath
  };

  return <BreadcrubmsContext.Provider value={value}>{children}</BreadcrubmsContext.Provider>;
};

export default BreadcrubmsContext;

/**
 * Copyright IBM Corp. 2016, 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import Fuse from 'fuse.js';
import React, { useState, useMemo, useEffect } from 'react';
import { Column, InlineLoading, Loading, Pagination, PaginationSkeleton, Row } from "@carbon/react";
import ProductsIndexList from './ProductsIndexList';
import ProductsIndexNotFound from './ProductsIndexNotFound';
import ProductsIndexSearch from './ProductsIndexSearch';
import ProductsIndexSort from './ProductsIndexSort';
import ProductsIndexFilters from './ProductsIndexFilters';
import { useDebounce } from '../../util/hooks/useDebounce';
import { useProductsData } from './useProductsData';
import BreadcrubmsContext from '../../util/context/BreadcrubmsContext';
import { useContext } from 'react';
import axios from 'axios';
import ProductsIndexSkeleton from './ProductsIndexSkeleton';



const searchOptions = {
  includeScore: true,
  threshold: 0.4,
  keys: [
    {
      name: 'article',
      weight: 2,
    },
    // 'short_desc',
    'description',
    'manufacturer.name',
  ],
};

function sortByName(a, b) {
  return a.article?.localeCompare(b.article);
}

function sortByBrand(a, b) {
  if (a.manufacturer.name === b.manufacturer.name) {
    return sortByName(a, b);
  }
  return a.manufacturer.name.localeCompare(b.manufacturer.name);
}

function sortByNewest(a, b) {
  const dateA = new Date(a.date_added);
  const dateB = new Date(b.date_added);
  return dateA - dateB;
}

const sortOptions = ['По алфавиту', 'По новизне'];
const initialSortOption = 'По алфавиту';
const sortBy = {
  'По алфавиту': sortByName,
  // Brand: sortByBrand,
  Newest: sortByNewest,
};

const filterLabels = [
  {
    key: "manufacturer.name",
    title: 'Бренд',
    options: [
      "Все"
    ],
  },
  {
    key: "name",
    title: 'Наименование',
    options: [
      "Все"
    ],
  },
];


function filterItems(items, filters) {
  return items.filter((item) => {
    // If there are no filters, return true.
    if (filters.length === 0) {
      return true;
    }
    const { manufacturer, name } = item;
    const brand = manufacturer.name;

    const fields = [brand, name];
    // console.log(fields)
    return filters.every((filter) => { return fields.includes(filter.value) });
  });
}

const API_URL = "https://api.industrator.ru/v1";

function objectToQuerystring(obj) {
  return Object.keys(obj).reduce(function (str, key, i) {
    var delimiter, val;
    delimiter = (i === 0) ? '?' : '&';
    key = encodeURIComponent(key);
    val = encodeURIComponent(JSON.stringify(obj[key]));
    return [str, delimiter, key, '=', val].join('');
  }, '');
}

const getProductData = async (
  api,
  filters
) => {
  let uri = objectToQuerystring(filters);
  const response = await axios.get(
    `${API_URL}${api}${uri}`
  )
  let totalCount = 0;
  if ('content-range' in response.headers) {
    totalCount = Number.parseInt(response.headers['content-range'].split('/')[1]);
  }
  const products = response.data;
  return { totalCount, products }
}

function ProductsIndexPage(props) {
  // const { brands, names } = [];//useProductsData(props.filter);
  // const { products } = props;
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [names, setNames] = useState([]);

  const [activeSortOption, setActiveSortOption] = useState(initialSortOption);
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 300);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const getData = (pageSize = currentPageSize, page = currentPage, search = debouncedSearchValue) => {
    setLoading(true);
    setProducts([]);
    const startOffset = pageSize * (page) - 1;
    const endOffset = (startOffset + pageSize) - 1;
    const filters = search ? {
      article: search,
      brand_id: props.filters.brand
    } : { brand_id: props.filters.brand };
    getProductData('/products', {
      "range": [startOffset, endOffset],
      "filter": filters
    }).then((result) => {
      setProducts(result.products);
      setTotalItems(result.totalCount);
      result.products.map((node) => {
        if (!names.includes(node.name)) {
          names.push(node.name);
        }
      });
    }).finally(() => {
      setLoading(false);
    })
  }

  useEffect(() => {
    getData();
  }, []);


  filterLabels[0].options = ["Все", ...brands];
  filterLabels[1].options = ["Все", ...names];

  const handleOnChange = (title, value) => {

    // Remove unchecked filter option(s) from setSelected state.
    const checkFilter = selected.filter((checkedFilter) => checkedFilter.title === title);
    if (checkFilter.length) {
      const newFilters = selected.filter((checkedFilter) => checkedFilter.title !== title);
      const data = [...newFilters, { title, value }];
      // console.log("2", data)
      if (value === "Все")
        setSelected(newFilters);
      else {
        setSelected(data);
      }

      // console.log(newFilters)
    } else {
      const data = [...selected, { title, value }];
      // console.log("1", data)
      setSelected(data);
    }
  };

  const handleOnSearchClear = () => {
    setSearchValue('');
    getData(currentPageSize, currentPage, null);
  }


  // console.log(debouncedSearchValue);
  useEffect(() => {
    // if (debouncedSearchValue !== '') {
    getData(currentPageSize, currentPage);
    // }
  }, [debouncedSearchValue]);



  let results;
  if (products.length === 0 && !loading) {
    results = <ProductsIndexNotFound />;
  } else if (loading && products.length === 0) {
    results = <ProductsIndexSkeleton size={currentPageSize} />
  } else {
    results = <ProductsIndexList items={products} />;
  }

  const {
    path,
    setPath,
    setIsBrands
  } = useContext(BreadcrubmsContext);

  useEffect(() => {
    if (!props.hideBrand) {
      setIsBrands(false);
    }
    setPath(props.path);
  }, [])

  return (
    <Row>
      <Column
        className="component-index-container cds--no-gutter">

        <ProductsIndexSearch value={searchValue} onChange={setSearchValue} onClear={handleOnSearchClear} />
        {/*<ProductsIndexFilters
          hideBrand={props.hideBrand}
          filters={filterLabels}
          onFilter={(checkedOption, selectedFilter) => {
            // console.log(checkedOption, selectedFilter)
            handleOnChange(checkedOption, selectedFilter)
          }
          }

          initialSortOption={initialSortOption}
          sortOptions={sortOptions}
          onSort={setActiveSortOption}
        /> */}

        {results}

        {loading && !totalItems ? (<PaginationSkeleton />) : (
          <>
            {totalItems > 0 && (
              <Pagination
                totalItems={totalItems}
                backwardText="Предыдущая страница"
                forwardText="Следующая страница"
                itemRangeText={(min, max, total) => {
                  return "".concat(min, "\u2013").concat(max, " из ").concat(total, " продуктов ");
                }}
                pageRangeText={(current, total) => {
                  return "из ".concat(total, " ").concat(total === 1 ? 'страницы' : 'страниц');
                }}
                pageSize={currentPageSize}
                page={currentPage}
                pageSizes={[6, 12, 18, 24]}
                itemsPerPageText="Продуктов на странице"
                onChange={({ page, pageSize }) => {
                  if (pageSize !== currentPageSize) {
                    setCurrentPageSize(pageSize);
                  }
                  setCurrentPage(page);
                  getData(pageSize, page)
                }}
              />)}
          </>
        )}
      </Column>
    </Row>
  );
}

export default ProductsIndexPage;
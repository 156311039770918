// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Header-module--cds--assistive-text--54a3b";
export var cdsLayoutConstraintDensity__defaultCondensed = "Header-module--cds--layout-constraint--density__default-condensed--2d0f4";
export var cdsLayoutConstraintDensity__defaultNormal = "Header-module--cds--layout-constraint--density__default-normal--3944a";
export var cdsLayoutConstraintDensity__maxCondensed = "Header-module--cds--layout-constraint--density__max-condensed--64700";
export var cdsLayoutConstraintDensity__maxNormal = "Header-module--cds--layout-constraint--density__max-normal--e89c8";
export var cdsLayoutConstraintDensity__minCondensed = "Header-module--cds--layout-constraint--density__min-condensed--d547b";
export var cdsLayoutConstraintDensity__minNormal = "Header-module--cds--layout-constraint--density__min-normal--a43bf";
export var cdsLayoutConstraintSize__default2xl = "Header-module--cds--layout-constraint--size__default-2xl--255a0";
export var cdsLayoutConstraintSize__defaultLg = "Header-module--cds--layout-constraint--size__default-lg--0dfce";
export var cdsLayoutConstraintSize__defaultMd = "Header-module--cds--layout-constraint--size__default-md--ff008";
export var cdsLayoutConstraintSize__defaultSm = "Header-module--cds--layout-constraint--size__default-sm--29fb9";
export var cdsLayoutConstraintSize__defaultXl = "Header-module--cds--layout-constraint--size__default-xl--3c6c8";
export var cdsLayoutConstraintSize__defaultXs = "Header-module--cds--layout-constraint--size__default-xs--858ac";
export var cdsLayoutConstraintSize__max2xl = "Header-module--cds--layout-constraint--size__max-2xl--58e42";
export var cdsLayoutConstraintSize__maxLg = "Header-module--cds--layout-constraint--size__max-lg--beb34";
export var cdsLayoutConstraintSize__maxMd = "Header-module--cds--layout-constraint--size__max-md--a8109";
export var cdsLayoutConstraintSize__maxSm = "Header-module--cds--layout-constraint--size__max-sm--8befb";
export var cdsLayoutConstraintSize__maxXl = "Header-module--cds--layout-constraint--size__max-xl--8cb83";
export var cdsLayoutConstraintSize__maxXs = "Header-module--cds--layout-constraint--size__max-xs--ca73e";
export var cdsLayoutConstraintSize__min2xl = "Header-module--cds--layout-constraint--size__min-2xl--5e8a9";
export var cdsLayoutConstraintSize__minLg = "Header-module--cds--layout-constraint--size__min-lg--2d417";
export var cdsLayoutConstraintSize__minMd = "Header-module--cds--layout-constraint--size__min-md--d8a19";
export var cdsLayoutConstraintSize__minSm = "Header-module--cds--layout-constraint--size__min-sm--a9c67";
export var cdsLayoutConstraintSize__minXl = "Header-module--cds--layout-constraint--size__min-xl--1730c";
export var cdsLayoutConstraintSize__minXs = "Header-module--cds--layout-constraint--size__min-xs--b9199";
export var cdsLayoutDensityCondensed = "Header-module--cds--layout--density-condensed--9cb8e";
export var cdsLayoutDensityNormal = "Header-module--cds--layout--density-normal--bdf8e";
export var cdsLayoutSize2xl = "Header-module--cds--layout--size-2xl--431df";
export var cdsLayoutSizeLg = "Header-module--cds--layout--size-lg--2f0fa";
export var cdsLayoutSizeMd = "Header-module--cds--layout--size-md--9bd8a";
export var cdsLayoutSizeSm = "Header-module--cds--layout--size-sm--2ccd9";
export var cdsLayoutSizeXl = "Header-module--cds--layout--size-xl--d9461";
export var cdsLayoutSizeXs = "Header-module--cds--layout--size-xs--7a3f0";
export var cdsVisuallyHidden = "Header-module--cds--visually-hidden--d6994";
export var header = "Header-module--header--40e77";
export var headerButton = "Header-module--header-button--bf341";
export var headerName = "Header-module--headerName--99cbc";
export var headerNameWithHeaderNav = "Header-module--headerNameWithHeaderNav--454c3";
export var headerWithHeaderNav = "Header-module--headerWithHeaderNav--08063";
export var hideFeedback = "Header-module--hide-feedback--9cfe4";
export var logo = "Header-module--logo--5ec0f";
export var searchIsOpenOnBar = "Header-module--searchIsOpenOnBar--b3436";
export var searchIsOpenOnLink = "Header-module--searchIsOpenOnLink--108b7";
export var showFeedback = "Header-module--show-feedback--332c8";
export var skeleton = "Header-module--skeleton--08d39";
export var skipToContent = "Header-module--skip-to-content--3f9d5";
export var switcherButton = "Header-module--switcher-button--b509c";
export var switcherButtonOpen = "Header-module--switcher-button--open--58fa1";
import React from 'react';
import ArrowRight20 from '@carbon/icons-react/es/arrow--right/20.js';
// Below path will be there when an application installs `@carbon/ibmdotcom-web-components` package.
// In our dev env, we auto-generate the file and re-map below path to to point to the generated file.
// @ts-ignore
import DDSCard from '@carbon/ibmdotcom-web-components/es/components-react/card/card';
// @ts-ignore
import DDSCardHeading from '@carbon/ibmdotcom-web-components/es/components-react/card/card-heading';
// @ts-ignore
import DDSCardFooter from '@carbon/ibmdotcom-web-components/es/components-react/card/card-footer';
// @ts-ignore
import DDSImage from '@carbon/ibmdotcom-web-components/es/components-react/image/image';

import './_carousel.scss';

const hrefDefault = '/';
const headingDefault = 'Lorem ipsum dolor sit amet';
const copyDefault = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et ultricies est.';
const copyOdd = `
  ${copyDefault}
  Mauris iaculis eget dolor nec hendrerit. Phasellus at elit sollicitudin, sodales nulla quis, consequat libero.
`;

const CarouselCard = ({ copy = copyDefault, color = 'light', heading = headingDefault, href = hrefDefault, image = undefined } = {}) => (
    <div className='cds--tile cds--card cds--tile--clickable cds--card--link cds--card--inverse cds--card__imaged' href={href} colorScheme={color}>
        {/* <DDSCardHeading>{heading}</DDSCardHeading> */}
        {/* {copy} */}
        {/* <DDSImageLogo slot="image" alt={alt} default-src={defaultSrc}></DDSImageLogo> */}
        <img className='cds--image__img' alt="example image" src={image} />
        {/* <DDSCardFooter>
            <ArrowRight20 slot="icon" />
        </DDSCardFooter> */}
    </div>
);

export default CarouselCard;
import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';
import { TransmissionLte32 } from "@carbon/icons-react";
import logoImg from '../../../images/logo-footer.svg';

import { logo } from 'gatsby-theme-carbon/src/components/Footer/Footer.module.scss';

const Content = ({ buildTime }) => (
  <>
    <p>
      Контент был сгенерирован: {buildTime}
    </p>
  </>
);

const links = {
  firstCol: [
    { href: '/content/for-suppliers', linkText: 'Для поставщиков' },
  ],
  secondCol: [
    { href: '/', linkText: 'Главная страница' },
  ],
};

const IBMLogo = () => (
  <svg
    className={logo} width="31" height="31" viewBox="0 0 31 31">
    
  </svg>
);


const CustomFooter = () => <Footer links={links} Content={Content} Logo={IBMLogo} />;

export default CustomFooter;

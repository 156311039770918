/**
 * Copyright IBM Corp. 2016, 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import PropTypes from 'prop-types';
import React from 'react';
import ProductsIndexListItem from './ProductsIndexListItem';

function ProductsIndexList({ items }) {
  return (
    <div className="catalog-categories-pane grid-view">
      <div className="category-section all_categories">
        {items.map(
          ({
            id,
            description,
            article,
            name,
            brand_id,
            slug,
            //  service,
            image,
            categories,
            //  extLink
          }) => {
            const key = `${id}`;
            return (
              <ProductsIndexListItem
                key={key}
                id={id}
                brand_id={brand_id}
                description={description}
                categories={categories}
                article={article}
                image={image}
                name={name}
                slug={slug}
              //  extLink={extLink}
              />
            );
          }
        )}
      </div>
    </div>
  );
}

ProductsIndexList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      article: PropTypes.string.isRequired,
      category: PropTypes.string,
      description: PropTypes.string
    })
  ),
};

export default ProductsIndexList;
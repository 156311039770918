import React from 'react';
import Header from 'gatsby-theme-carbon/src/components/Header';
import { logo } from './Header.module.scss';
import logoS from '../../../images/logo-top.svg';

const IndustratorLogo = () => (
  <img src={logoS} className={logo} width="20" height="20" viewBox="0 0 31 31" />
);

const CustomHeader = (props) => (
  <Header {...props}>
    <IndustratorLogo />&nbsp;<span>Industrator</span>
  </Header>
);

export default CustomHeader;

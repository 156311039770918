/**
 * Copyright IBM Corp. 2016, 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { Row, Column, Link } from "@carbon/react";
import React from 'react';

const coreComponentsLink = '/components/overview';

function BrandsIndexNotFound() {
  return (
    <div className="component-index-not-found">
      <Row>
        <Column sm={4} md={6} lg={6}>
          <h2 className="component-index-not-found__title">Ничего не найдено</h2>
          <p className="component-index-not-found__text">
            По вашему запросу не было найдено товаров. Попробуйте изменить запрос и повторить попытку.
          </p>
        </Column>
      </Row>
    </div>
  );
}

export default BrandsIndexNotFound;

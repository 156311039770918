import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Column, SquareCard, Row } from 'gatsby-theme-carbon';

export default function BrandEnteties(props) {

  const color = props.color || 'dark';
  const count = props.count || 0;

  const data = useStaticQuery(graphql`
  {
    allIndustratorBrand {
      nodes {
        id
        slug
        article
        description
      }
    }
  }
  
  
  `);

  let allIndustratorBrandNodes = [];
  if (count) {
    allIndustratorBrandNodes = data.allIndustratorBrand.nodes.slice(0, count);
  } else {
    allIndustratorBrandNodes = data.allIndustratorBrand.nodes;
  }

  const allIndustratorBrand = allIndustratorBrandNodes.map(node => {
    const { id, slug, article } = node;
    return {
      id,
      slug,
      article
    }
  });
  return (
    <Row className="square-card-group">
      {allIndustratorBrand.map((brand, idx) => (
        <SquareCard key={brand.slug}
          color={color}
          href={`/brand/${brand.slug}`}
          title={brand.article}
          helperText={`Товаров: ${brand.count}`}
          actionIcon="arrowRight"
          className=""
        />
      ))}
      <SquareCard
          color={color}
          href={`/brands/list`}
          // title={'Все бренды'}
          helperText={'Все бренды'}
          actionIcon="arrowRight"
          className=""
        />


    </Row>
  )
}

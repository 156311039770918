import React from 'react';
import { HomepageCallout } from 'gatsby-theme-carbon';
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import MediumPosts from 'gatsby-theme-carbon/src/components/MediumPosts';
import '../components/Homepage/Callout.module.scss';
import { calloutLink, secondColumn } from './Homepage.module.scss';
import HomepageVideo from '../../components/HomepageVideo/HomepageVideo';
import { blue90, gray10, white0, gray90 } from '@carbon/elements';
import ArticlesIndex from '../../components/ArticlesIndex/ArticlesIndex';
import { Column, Grid, Row } from "@carbon/react";


const FirstLeftText = () => <p>Industrator</p>;

const FirstRightText = () => (
  <p className={secondColumn}>
    Маркетплейс индустриальной продукции и технологий. Наши цифровые решения
    способствуют объединению и сотрудничеству производителей, поставщиков и заказчиков
    промышленной продукции.
    <a
      className={calloutLink}
      href="https://about.industrator.ru">
      Узнать больше →
    </a>
  </p>
);

const SecondLeftText = () => <p>Не нашли подходящую продукцию?</p>;

const SecondRightText = () => (
  <p>
    Для получения профессиональной помощи наших специалистов воспользуйтесь формой запроса
    <a
      className={calloutLink}
      href="/contacts">
      Отправить запрос →
    </a>
  </p>
);

const customProps = {
  Banner: (
    <>
      <span className="homepage--dots" />
      <HomepageVideo
        src="/videos/hero-video.mp4"
        poster="/images/poster-hero.png"
      />
    </>
  ),
  FirstCallout: (
    <HomepageCallout
      backgroundColor="#030303"
      color={gray10}
      leftText={FirstLeftText}
      rightText={FirstRightText}
    />
  ),
  SecondCallout: (
    <>
      <ArticlesIndex
        backgroundColor={gray90}
        pageSize={3}
        items={[
          {
            href: "/",
            image: "/images/new/01.png",
            heading: "GROB",
            copy: "Новый высокопроизводительный фрезерный обрабатывающий центр G700",
            color: "inverse",
          },
          {
            href: "/",
            image: "/images/new/01.png",
            heading: "KINTEK",
            copy: "Новая высокоэффективная зажимная система для станков серии GENIUS",
          },
          {
            href: "/",
            image: "/images/new/01.png",
            heading: "Indersoll Rand",
            copy: "Насосы нового поколения серии TREK UP",
          },
          {
            href: "/",
            image: "/images/new/01.png",
            heading: "SANDVIK",
            copy: "Приницпиально новый стружколом WIPER для высокоропроизводительной обработки заколанных сталей",
          },
          {
            href: "/",
            image: "/images/new/01.png",
            heading: "SANDVIK",
            copy: "Приницпиально новый стружколом WIPER для высокоропроизводительной обработки заколанных сталей",
          },
          {
            href: "/",
            image: "/images/new/01.png",
            heading: "SANDVIK",
            copy: "Приницпиально новый стружколом WIPER для высокоропроизводительной обработки заколанных сталей",
          },
        ]}
      />
      <HomepageCallout
        leftText={SecondLeftText}
        rightText={SecondRightText}
        color={white0}
        backgroundColor={blue90}
      />
    </>

  ),
};

// spreading the original props gives us props.children (mdx content)
function ShadowedHomepage(props) {
  return <HomepageTemplate {...props} {...customProps} />;
}

export default ShadowedHomepage;

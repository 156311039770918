// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "ArticlesIndex-module--cds--assistive-text--b5d30";
export var cdsLayoutConstraintDensity__defaultCondensed = "ArticlesIndex-module--cds--layout-constraint--density__default-condensed--3726d";
export var cdsLayoutConstraintDensity__defaultNormal = "ArticlesIndex-module--cds--layout-constraint--density__default-normal--94088";
export var cdsLayoutConstraintDensity__maxCondensed = "ArticlesIndex-module--cds--layout-constraint--density__max-condensed--fc4ef";
export var cdsLayoutConstraintDensity__maxNormal = "ArticlesIndex-module--cds--layout-constraint--density__max-normal--1515f";
export var cdsLayoutConstraintDensity__minCondensed = "ArticlesIndex-module--cds--layout-constraint--density__min-condensed--7dff1";
export var cdsLayoutConstraintDensity__minNormal = "ArticlesIndex-module--cds--layout-constraint--density__min-normal--16801";
export var cdsLayoutConstraintSize__default2xl = "ArticlesIndex-module--cds--layout-constraint--size__default-2xl--eeb88";
export var cdsLayoutConstraintSize__defaultLg = "ArticlesIndex-module--cds--layout-constraint--size__default-lg--985d6";
export var cdsLayoutConstraintSize__defaultMd = "ArticlesIndex-module--cds--layout-constraint--size__default-md--a9fdf";
export var cdsLayoutConstraintSize__defaultSm = "ArticlesIndex-module--cds--layout-constraint--size__default-sm--949dd";
export var cdsLayoutConstraintSize__defaultXl = "ArticlesIndex-module--cds--layout-constraint--size__default-xl--2d124";
export var cdsLayoutConstraintSize__defaultXs = "ArticlesIndex-module--cds--layout-constraint--size__default-xs--01fd1";
export var cdsLayoutConstraintSize__max2xl = "ArticlesIndex-module--cds--layout-constraint--size__max-2xl--db833";
export var cdsLayoutConstraintSize__maxLg = "ArticlesIndex-module--cds--layout-constraint--size__max-lg--1c67a";
export var cdsLayoutConstraintSize__maxMd = "ArticlesIndex-module--cds--layout-constraint--size__max-md--12fce";
export var cdsLayoutConstraintSize__maxSm = "ArticlesIndex-module--cds--layout-constraint--size__max-sm--abfff";
export var cdsLayoutConstraintSize__maxXl = "ArticlesIndex-module--cds--layout-constraint--size__max-xl--29199";
export var cdsLayoutConstraintSize__maxXs = "ArticlesIndex-module--cds--layout-constraint--size__max-xs--1b655";
export var cdsLayoutConstraintSize__min2xl = "ArticlesIndex-module--cds--layout-constraint--size__min-2xl--ae29f";
export var cdsLayoutConstraintSize__minLg = "ArticlesIndex-module--cds--layout-constraint--size__min-lg--1084e";
export var cdsLayoutConstraintSize__minMd = "ArticlesIndex-module--cds--layout-constraint--size__min-md--b8ce0";
export var cdsLayoutConstraintSize__minSm = "ArticlesIndex-module--cds--layout-constraint--size__min-sm--73b7b";
export var cdsLayoutConstraintSize__minXl = "ArticlesIndex-module--cds--layout-constraint--size__min-xl--65fe1";
export var cdsLayoutConstraintSize__minXs = "ArticlesIndex-module--cds--layout-constraint--size__min-xs--bc64a";
export var cdsLayoutDensityCondensed = "ArticlesIndex-module--cds--layout--density-condensed--4bb43";
export var cdsLayoutDensityNormal = "ArticlesIndex-module--cds--layout--density-normal--27238";
export var cdsLayoutSize2xl = "ArticlesIndex-module--cds--layout--size-2xl--c36bd";
export var cdsLayoutSizeLg = "ArticlesIndex-module--cds--layout--size-lg--fd59d";
export var cdsLayoutSizeMd = "ArticlesIndex-module--cds--layout--size-md--c91a0";
export var cdsLayoutSizeSm = "ArticlesIndex-module--cds--layout--size-sm--4e5aa";
export var cdsLayoutSizeXl = "ArticlesIndex-module--cds--layout--size-xl--f55c0";
export var cdsLayoutSizeXs = "ArticlesIndex-module--cds--layout--size-xs--8885f";
export var cdsVisuallyHidden = "ArticlesIndex-module--cds--visually-hidden--1b644";
export var grid = "ArticlesIndex-module--grid--8725e";
export var hideFeedback = "ArticlesIndex-module--hide-feedback--52ebf";
export var row = "ArticlesIndex-module--row--18745";
export var showFeedback = "ArticlesIndex-module--show-feedback--58937";
export var skeleton = "ArticlesIndex-module--skeleton--04f7e";
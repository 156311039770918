/**
 * Copyright IBM Corp. 2016, 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { IconSkeleton, SkeletonText } from "@carbon/react";
import PropTypes from 'prop-types';
import React from 'react';
function ProductsIndexSkeleton({ size = 6 }) {
    
    return (
        <div className="catalog-categories-pane grid-view">
            <div className="category-section all_categories">
                {[...Array(size).keys()].map((index) => {
                    const key = `${index}`;
                    return (
                        <div className="pal--catalog-tile">
                            {/* <div className="pal--catalog-tile" > */}
                            <div className="pal--catalog-tile__content-wrapper">
                                <div className="pal--catalog-tile__header-container">
                                    <div className="pal--catalog-tile__icon-container">
                                        <IconSkeleton />
                                    </div>
                                    <div className="pal--catalog-tile__header">
                                        <p className="pal--catalog-tile__header-name"><SkeletonText /></p>
                                        <ul className="pal--catalog-tile__tag-container">
                                            <li className="pal--catalog-tile__tag"></li></ul>
                                    </div>
                                </div>
                                <div className="pal--catalog-tile__desc-container">
                                    <div className="pal--catalog-tile__desc">
                                        <div className="pal--catalog-tile__desc-ellipsis">
                                            <p className="pal--catalog-tile__desc"><SkeletonText /></p>
                                        </div>
                                    </div>
                                    <ul className="pal--catalog-tile__tag-container">
                                        <SkeletonText />

                                    </ul>
                                </div>
                            </div>
                        </div>
                    );
                }
                )}
            </div>
        </div>
    );
}

ProductsIndexSkeleton.propTypes = {
    size: PropTypes.number.isRequired,
};

export default ProductsIndexSkeleton;
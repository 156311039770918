exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brands-[slug]-js": () => import("./../../../src/pages/brands/[slug].js" /* webpackChunkName: "component---src-pages-brands-[slug]-js" */),
  "component---src-pages-brands-index-mdx": () => import("./../../../src/pages/brands/index.mdx" /* webpackChunkName: "component---src-pages-brands-index-mdx" */),
  "component---src-pages-category-industrator-category-tree-js": () => import("./../../../src/pages/category/{IndustratorCategory.tree}.js" /* webpackChunkName: "component---src-pages-category-industrator-category-tree-js" */),
  "component---src-pages-contacts-mdx": () => import("./../../../src/pages/contacts.mdx" /* webpackChunkName: "component---src-pages-contacts-mdx" */),
  "component---src-pages-content-for-suppliers-mdx": () => import("./../../../src/pages/content/for-suppliers.mdx" /* webpackChunkName: "component---src-pages-content-for-suppliers-mdx" */),
  "component---src-pages-contributions-mdx": () => import("./../../../src/pages/contributions.mdx" /* webpackChunkName: "component---src-pages-contributions-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-product-[slug]-js": () => import("./../../../src/pages/product/[slug].js" /* webpackChunkName: "component---src-pages-product-[slug]-js" */)
}


/**
 * Copyright IBM Corp. 2016, 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import Fuse from 'fuse.js';
import React, { useState, useMemo, useEffect } from 'react';
import { Column, Pagination, Row } from "@carbon/react";
import BrandsIndexList from './BrandsIndexList';
import BrandsIndexNotFound from './BrandsIndexNotFound';
import BrandsIndexSearch from './BrandsIndexSearch';
import BrandsIndexSort from './BrandsIndexSort';
import BrandsIndexFilters from './BrandsIndexFilters';
import { useDebounce } from '../../util/hooks/useDebounce';
import { useBrandsData } from './useBrandsData';
import BreadcrubmsContext from '../../util/context/BreadcrubmsContext';
import { useContext } from 'react';
import { useNavItems } from '../../gatsby-theme-carbon/util/NavItems';



const searchOptions = {
  includeScore: true,
  findAllMatches: true,
  minMatchCharLength: 2,
  threshold: 0.1,
  keys: [
    {
      name: 'article',
      weight: 0.2,
    },
    // 'short_desc',
    // 'description',
  ],
};

function sortByName(a, b) {
  return a.article?.localeCompare(b.article);
}
function sortByName2(a, b) {
  return b.article?.localeCompare(a.article);
}

function sortByBrand(a, b) {
  if (a.manufacturer.name === b.manufacturer.name) {
    return sortByName(a, b);
  }
  return a.manufacturer.name.localeCompare(b.manufacturer.name);
}

function sortByNewest(a, b) {
  const dateA = new Date(a.date_added);
  const dateB = new Date(b.date_added);
  return dateA - dateB;
}

const sortOptions = ['По алфавиту', 'По алфавиту (Z-A)'];
const initialSortOption = 'По алфавиту';
const sortBy = {
  'По алфавиту': sortByName,
  // Brand: sortByBrand,
  'По алфавиту (Z-A)': sortByName2,
};


const filterLabels = [
  {
    key: "categories",
    title: 'Категории',
    options: [
      "Все"
    ],
  },
  // {
  //   key: "categories2",
  //   title: 'Под категории',
  //   options: [
  //     "Все"
  //   ],
  // }
];

function BrandsIndexPage(props) {
  const { brands, allCategories } = useBrandsData(props.filter);
  const [activeSortOption, setActiveSortOption] = useState(initialSortOption);
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 300);
  const searchClient = useMemo(
    () => new Fuse(brands, searchOptions),
    [brands]
  );
  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(6);


  filterLabels[0].options = ["Все", ...allCategories];

  const categoriesA = useNavItems();

  function filterItems(items, filters) {
    return items.filter((item) => {
      // If there are no filters, return true.
      if (filters.length === 0) {
        return true;
      }
      const { categories_ids } = item;
      const categories = [];
      categories_ids?.forEach(element => {
        const cat = categoriesA.filter(c => c.id === `category-${element}`);
        if (cat.length) {
          categories.push(cat[0].title);
        }
        // while (element.charAt(0) === ' ') {
        //   element = element.substring(1);
        // }
        // if (!allCategories.includes(element)) {
        //   allCategories.push(element);
        // }
      });

      const fields = categories;
      // console.log(filters)
      return filters.every((filter) => {
        // console.log(fields)
        return fields.includes(filter.value)
      });
    });
  }

  // const alcid = allCategories.map(c => c.id);
  // const subcats = categoriesA.filter(cat => {
  //   return alcid.includes(`category-${cat.parent_id}`);
  // }).map(c => c.title);
  // filterLabels[1].options = ["Все", ...subcats];

  const handleOnChange = (title, value) => {

    // Remove unchecked filter option(s) from setSelected state.
    const checkFilter = selected.filter((checkedFilter) => checkedFilter.title === title);
    if (checkFilter.length) {
      const newFilters = selected.filter((checkedFilter) => checkedFilter.title !== title);
      const data = [...newFilters, { title, value }];
      // console.log("2", data)
      if (value === "Все")
        setSelected(newFilters);
      else {
        setSelected(data);
      }

      // console.log(newFilters)
    } else {
      const data = [...selected, { title, value }];
      // console.log("1", data)
      setSelected(data);
    }
  };

  let searchResults = brands;
  if (debouncedSearchValue !== '') {
    searchResults = searchClient
      .search(debouncedSearchValue)
      .map((result) => result.item);
  }

  let results;
  const indexItemsPre = filterItems(searchResults.slice(), selected).sort(
    sortBy[activeSortOption]
  );
  useEffect(() => {
    setTotalItems(indexItemsPre.length);
  }, [indexItemsPre])
  const indexItems = indexItemsPre.slice(
    firstRowIndex,
    firstRowIndex + currentPageSize
  );

  if (searchResults.length === 0 || indexItems.length === 0) {
    results = <BrandsIndexNotFound />;
  } else {
    results = <BrandsIndexList items={indexItems} />;
  }

  const {
    path,
    setPath,
    setIsBrands
  } = useContext(BreadcrubmsContext);

  useEffect(() => {
    if (!props.hideBrand) {
      setIsBrands(false);
    }
    setPath(props.path);
  }, [])

  return (
    <Row>
      <Column
        className="component-index-container cds--no-gutter">

        <BrandsIndexSearch value={searchValue} onChange={setSearchValue} />
        <BrandsIndexFilters
          hideBrand={props.hideBrand}
          filters={filterLabels}
          onFilter={(checkedOption, selectedFilter) => {
            // console.log(checkedOption, selectedFilter)
            handleOnChange(checkedOption, selectedFilter)
          }
          }

          initialSortOption={initialSortOption}
          sortOptions={sortOptions}
          onSort={setActiveSortOption}
        />

        {results}

        <Pagination
          totalItems={totalItems}
          backwardText="Предыдущая страница"
          forwardText="Следующая страница"
          itemRangeText={(min, max, total) => {
            return "".concat(min, "\u2013").concat(max, " из ").concat(total, " брендов ");
          }}
          pageRangeText={(current, total) => {
            return "из ".concat(total, " ").concat(total === 1 ? 'страницы' : 'страниц');
          }}
          pageSize={currentPageSize}
          pageSizes={[6, 12, 18, 24]}
          itemsPerPageText="Брендов на странице"
          onChange={({ page, pageSize }) => {
            if (pageSize !== currentPageSize) {
              setCurrentPageSize(pageSize);
            }
            setFirstRowIndex(pageSize * (page - 1));
          }}
        />
      </Column>
    </Row>
  );
}

export default BrandsIndexPage;
import React from 'react';
import { Grid, Column, Row } from 'gatsby-theme-carbon/src/components/Grid';

import { grid, row } from './ArticlesIndex.module.scss';
import theme from 'gatsby-theme-carbon/src/util/theme';
import CarouselCard from '../Carousel/CarouselCard';
import DDSCarousel from '@carbon/ibmdotcom-web-components/es/components-react/carousel/carousel';

const getBackgroundColor = (backgroundColor) =>
  theme.colors[backgroundColor] ||
  backgroundColor ||
  theme.colors.interactive02;

const getColor = (color) =>
  theme.colors[color] || color || theme.colors.inverse01;

const ArticlesIndex = ({
  leftText,
  rightText,
  backgroundColor: backgroundColorProp,
  color: colorProp,
  items,
  pageSize = 3,
  ...rest
}) => {
  const backgroundColor = getBackgroundColor(backgroundColorProp);
  const color = getColor(colorProp);
  return (
    <Grid
      style={{ backgroundColor, color, width: '100%', maxWidth: '100%' }}
      className={grid}
      {...rest}>
      <Row className={row}>
        <Column colLg={12} colMd={12} >
          <h2>Статьи и обзоры</h2>
          <DDSCarousel style={{ marginTop: "1.5rem"}} pageSize={pageSize}>
          {items.map((item, idx) => (
            <CarouselCard
              logo={item.logo}
              color={item.color}
              key={`carousel-${idx}`}
              href={item.href}
              image={item.image}
              heading={item.heading}
              copy={item.copy}
            />
          ))}
        </DDSCarousel>
      </Column>
    </Row>
    </Grid >
  );
};

ArticlesIndex.defaultProps = {
  leftText: function renderLeftText() {
    return (
      <>
        Think → <strong>Guide</strong>
      </>
    );
  },
  rightText: function renderRightText() {
    return (
      <p>
        <strong>Build Bonds.</strong>
        <br />
        This is the guiding ethos behind IBM’s design philosophy and principles.
        This helps us distinguish every element and every experience Designed by
        IBM.
      </p>
    );
  },
};

export default ArticlesIndex;

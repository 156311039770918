// extracted by mini-css-extract-plugin
export var active = "GlobalSearch-module--active--49877";
export var cdsAssistiveText = "GlobalSearch-module--cds--assistive-text--e793b";
export var cdsLayoutConstraintDensity__defaultCondensed = "GlobalSearch-module--cds--layout-constraint--density__default-condensed--28aa1";
export var cdsLayoutConstraintDensity__defaultNormal = "GlobalSearch-module--cds--layout-constraint--density__default-normal--6424a";
export var cdsLayoutConstraintDensity__maxCondensed = "GlobalSearch-module--cds--layout-constraint--density__max-condensed--5ce0a";
export var cdsLayoutConstraintDensity__maxNormal = "GlobalSearch-module--cds--layout-constraint--density__max-normal--55b90";
export var cdsLayoutConstraintDensity__minCondensed = "GlobalSearch-module--cds--layout-constraint--density__min-condensed--c27d7";
export var cdsLayoutConstraintDensity__minNormal = "GlobalSearch-module--cds--layout-constraint--density__min-normal--b4501";
export var cdsLayoutConstraintSize__default2xl = "GlobalSearch-module--cds--layout-constraint--size__default-2xl--61c2d";
export var cdsLayoutConstraintSize__defaultLg = "GlobalSearch-module--cds--layout-constraint--size__default-lg--f337c";
export var cdsLayoutConstraintSize__defaultMd = "GlobalSearch-module--cds--layout-constraint--size__default-md--a2fd9";
export var cdsLayoutConstraintSize__defaultSm = "GlobalSearch-module--cds--layout-constraint--size__default-sm--c9227";
export var cdsLayoutConstraintSize__defaultXl = "GlobalSearch-module--cds--layout-constraint--size__default-xl--1cbc1";
export var cdsLayoutConstraintSize__defaultXs = "GlobalSearch-module--cds--layout-constraint--size__default-xs--9ee02";
export var cdsLayoutConstraintSize__max2xl = "GlobalSearch-module--cds--layout-constraint--size__max-2xl--f5427";
export var cdsLayoutConstraintSize__maxLg = "GlobalSearch-module--cds--layout-constraint--size__max-lg--4e851";
export var cdsLayoutConstraintSize__maxMd = "GlobalSearch-module--cds--layout-constraint--size__max-md--19542";
export var cdsLayoutConstraintSize__maxSm = "GlobalSearch-module--cds--layout-constraint--size__max-sm--a06e4";
export var cdsLayoutConstraintSize__maxXl = "GlobalSearch-module--cds--layout-constraint--size__max-xl--487d1";
export var cdsLayoutConstraintSize__maxXs = "GlobalSearch-module--cds--layout-constraint--size__max-xs--1efe2";
export var cdsLayoutConstraintSize__min2xl = "GlobalSearch-module--cds--layout-constraint--size__min-2xl--563ca";
export var cdsLayoutConstraintSize__minLg = "GlobalSearch-module--cds--layout-constraint--size__min-lg--1432e";
export var cdsLayoutConstraintSize__minMd = "GlobalSearch-module--cds--layout-constraint--size__min-md--27c3c";
export var cdsLayoutConstraintSize__minSm = "GlobalSearch-module--cds--layout-constraint--size__min-sm--4d405";
export var cdsLayoutConstraintSize__minXl = "GlobalSearch-module--cds--layout-constraint--size__min-xl--bdba9";
export var cdsLayoutConstraintSize__minXs = "GlobalSearch-module--cds--layout-constraint--size__min-xs--8c53a";
export var cdsLayoutDensityCondensed = "GlobalSearch-module--cds--layout--density-condensed--7b899";
export var cdsLayoutDensityNormal = "GlobalSearch-module--cds--layout--density-normal--69d4d";
export var cdsLayoutSize2xl = "GlobalSearch-module--cds--layout--size-2xl--f9840";
export var cdsLayoutSizeLg = "GlobalSearch-module--cds--layout--size-lg--19722";
export var cdsLayoutSizeMd = "GlobalSearch-module--cds--layout--size-md--17353";
export var cdsLayoutSizeSm = "GlobalSearch-module--cds--layout--size-sm--dd353";
export var cdsLayoutSizeXl = "GlobalSearch-module--cds--layout--size-xl--4f607";
export var cdsLayoutSizeXs = "GlobalSearch-module--cds--layout--size-xs--8b774";
export var cdsVisuallyHidden = "GlobalSearch-module--cds--visually-hidden--953ca";
export var container = "GlobalSearch-module--container--84d2e";
export var description = "GlobalSearch-module--description--4175f";
export var hidden = "GlobalSearch-module--hidden--d13f5";
export var hideFeedback = "GlobalSearch-module--hide-feedback--e150c";
export var inactive = "GlobalSearch-module--inactive--6d1fe";
export var input = "GlobalSearch-module--input--7d1e9";
export var inputFocusWithin = "GlobalSearch-module--inputFocusWithin--d572e";
export var inputWrapper = "GlobalSearch-module--inputWrapper--798e0";
export var label = "GlobalSearch-module--label--c3904";
export var link = "GlobalSearch-module--link--d7164";
export var list = "GlobalSearch-module--list--d399f";
export var searchButton = "GlobalSearch-module--searchButton--dd8cd";
export var searchButtonClose = "GlobalSearch-module--search-button--close--0925c";
export var showFeedback = "GlobalSearch-module--show-feedback--6c453";
export var skeleton = "GlobalSearch-module--skeleton--647f9";
export var tab = "GlobalSearch-module--tab--3be3f";
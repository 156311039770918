// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "LeftNavWrapper-module--cds--assistive-text--d0c1e";
export var cdsLayoutConstraintDensity__defaultCondensed = "LeftNavWrapper-module--cds--layout-constraint--density__default-condensed--91bad";
export var cdsLayoutConstraintDensity__defaultNormal = "LeftNavWrapper-module--cds--layout-constraint--density__default-normal--5f100";
export var cdsLayoutConstraintDensity__maxCondensed = "LeftNavWrapper-module--cds--layout-constraint--density__max-condensed--ad1e4";
export var cdsLayoutConstraintDensity__maxNormal = "LeftNavWrapper-module--cds--layout-constraint--density__max-normal--584b2";
export var cdsLayoutConstraintDensity__minCondensed = "LeftNavWrapper-module--cds--layout-constraint--density__min-condensed--96bc0";
export var cdsLayoutConstraintDensity__minNormal = "LeftNavWrapper-module--cds--layout-constraint--density__min-normal--72492";
export var cdsLayoutConstraintSize__default2xl = "LeftNavWrapper-module--cds--layout-constraint--size__default-2xl--fbd9d";
export var cdsLayoutConstraintSize__defaultLg = "LeftNavWrapper-module--cds--layout-constraint--size__default-lg--e5ea8";
export var cdsLayoutConstraintSize__defaultMd = "LeftNavWrapper-module--cds--layout-constraint--size__default-md--a638b";
export var cdsLayoutConstraintSize__defaultSm = "LeftNavWrapper-module--cds--layout-constraint--size__default-sm--69734";
export var cdsLayoutConstraintSize__defaultXl = "LeftNavWrapper-module--cds--layout-constraint--size__default-xl--cc8eb";
export var cdsLayoutConstraintSize__defaultXs = "LeftNavWrapper-module--cds--layout-constraint--size__default-xs--2ee19";
export var cdsLayoutConstraintSize__max2xl = "LeftNavWrapper-module--cds--layout-constraint--size__max-2xl--9def0";
export var cdsLayoutConstraintSize__maxLg = "LeftNavWrapper-module--cds--layout-constraint--size__max-lg--117f6";
export var cdsLayoutConstraintSize__maxMd = "LeftNavWrapper-module--cds--layout-constraint--size__max-md--c658c";
export var cdsLayoutConstraintSize__maxSm = "LeftNavWrapper-module--cds--layout-constraint--size__max-sm--662a4";
export var cdsLayoutConstraintSize__maxXl = "LeftNavWrapper-module--cds--layout-constraint--size__max-xl--83ad9";
export var cdsLayoutConstraintSize__maxXs = "LeftNavWrapper-module--cds--layout-constraint--size__max-xs--0a689";
export var cdsLayoutConstraintSize__min2xl = "LeftNavWrapper-module--cds--layout-constraint--size__min-2xl--1fa85";
export var cdsLayoutConstraintSize__minLg = "LeftNavWrapper-module--cds--layout-constraint--size__min-lg--ade0e";
export var cdsLayoutConstraintSize__minMd = "LeftNavWrapper-module--cds--layout-constraint--size__min-md--7defa";
export var cdsLayoutConstraintSize__minSm = "LeftNavWrapper-module--cds--layout-constraint--size__min-sm--16404";
export var cdsLayoutConstraintSize__minXl = "LeftNavWrapper-module--cds--layout-constraint--size__min-xl--6a8bc";
export var cdsLayoutConstraintSize__minXs = "LeftNavWrapper-module--cds--layout-constraint--size__min-xs--7a9d5";
export var cdsLayoutDensityCondensed = "LeftNavWrapper-module--cds--layout--density-condensed--4e00b";
export var cdsLayoutDensityNormal = "LeftNavWrapper-module--cds--layout--density-normal--8a3f8";
export var cdsLayoutSize2xl = "LeftNavWrapper-module--cds--layout--size-2xl--ae0f3";
export var cdsLayoutSizeLg = "LeftNavWrapper-module--cds--layout--size-lg--1b81c";
export var cdsLayoutSizeMd = "LeftNavWrapper-module--cds--layout--size-md--3fe55";
export var cdsLayoutSizeSm = "LeftNavWrapper-module--cds--layout--size-sm--51c33";
export var cdsLayoutSizeXl = "LeftNavWrapper-module--cds--layout--size-xl--feddb";
export var cdsLayoutSizeXs = "LeftNavWrapper-module--cds--layout--size-xs--e3484";
export var cdsVisuallyHidden = "LeftNavWrapper-module--cds--visually-hidden--74805";
export var expanded = "LeftNavWrapper-module--expanded--3d9f2";
export var hideFeedback = "LeftNavWrapper-module--hide-feedback--3aa85";
export var leftNavWrapper = "LeftNavWrapper-module--leftNavWrapper--8a942";
export var showFeedback = "LeftNavWrapper-module--show-feedback--872b4";
export var skeleton = "LeftNavWrapper-module--skeleton--54585";
export var useHeaderNavStyles = "LeftNavWrapper-module--useHeaderNavStyles--c3a38";